<template>
  <div class="page-content">
    <div class="d-flex align-items-center justify-content-center" v-if="account_list.length == 0">
      <div class="row w-100 mx-0 auth-page">
        <div class="col-md-8 col-xl-6 mx-auto d-flex flex-column align-items-center">
          <img src="@/assets/images/undraw/online-trx.svg" class="img-fluid mb-2" alt="404">
          <h4 class="mb-2">Oops!</h4>
          <h6 class="text-muted mb-3 text-center">
            {{ $t('payments.no_account_first')}}
          </h6>
          <el-button @click="showModalAdd" type="primary"><font-awesome icon="plus"/> Create Settlement Account</el-button>
        </div>
      </div>
    </div>
    <div class="card row w-100" v-else>
      <div class="card-body col-md-12">
        <div class="ml-1 row d-flex justify-content-between align-items-center">
          <div class="row col-12 col-md-12">
            <b-card v-for="item in account_list" :key="item.id"
              border-variant="primary"
              header-text-variant="white"
              class="col-lg-4 mr-4"
              :class="{ 'bg-primary text-white': item.is_disbursement_account }"
              align="left">
              <div class="row">
                <b-card-text>{{ item.bank.name }}&nbsp;
                  <!-- <el-tag v-if="item.status == 'active'" size="small" effect="dark" :type="item.status_color">{{ item.status_str }}</el-tag> -->
                  <el-popover
                    placement="right"
                    width="400"
                    trigger="hover">
                    <h6 class="mb-2">Status</h6>
                    <p class="mb-2" style="white-space: pre-line; overflow-wrap: break-word;">{{ item.status_description }}</p>
                    <el-tag slot="reference" size="small" effect="dark" :type="item.status_color">{{ item.status_str }}</el-tag>
                  </el-popover>
                </b-card-text>
              </div>
              <div class="row mt-2">
                <b-card-text>
                  Account Number<h6>{{ censored(item.account_number) }}</h6>
                </b-card-text>
              </div>
              <div class="row mt-2 justify-content-between">
                <b-card-text>
                  Account Name<h6>{{ truncate(item.account_name, { length: 20 }) }}</h6>
                </b-card-text>
                <b-card-text>
                  Bank Branch City<h6>{{ item.bank_branch_city }}</h6>
                </b-card-text>
              </div>
            </b-card>
          </div>
        </div>
        <hr/>
        <h5>Transactions List</h5>
        <div class="table-responsive mt-2">
          <table class="table table-hover" v-loading="loading.trx_list">
            <thead>
              <tr>
                <th>Invoice No.</th>
                <th>Ticket No.</th>
                <!-- <th>Bank Account</th> -->
                <th>Amount</th>
                <th>Created Date</th>
                <th>Created By</th>
                <th>Status</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in trx_list" :key="item.id">
                <td>{{ item.invoice_number }}</td>
                <td>#{{ item.conversation.code }}</td>
                <!-- <td>{{ item.bank_account }}</td> -->
                <td>{{ item.amount_str }}</td>
                <td>{{ item.created_at_str }}</td>
                <td>{{ item.creator_name }}</td>
                <td><el-tag size="small" effect="dark" :type="item.status_color">{{ item.status_str }}</el-tag></td>
                <td>
                  <button alt="View Detail" @click="detailTransaction(item)" class="btn btn-outline-primary btn-sm">
                    <font-awesome icon="eye"/>
                  </button> &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty :image="emptyTransaction" v-if="total_row == 0" description="No data"></el-empty>
          <b-pagination v-if="total_row > page_limit" v-model="currentPage" :total-rows="total_row" :per-page="page_limit"/>
        </div>
      </div>
    </div>
    <create-settlement
      :show-modal-add="show_modal_add"
      :bank-list="bank_list"
      @handleSendFile="handleSendFile"
      @submitCreateAccount="submitCreateAccount"
      @closeModalAdd="closeModalAdd" />
    <transaction-detail
      :show-modal="show_modal_trx"
      :trx-details="trx_details"
      @closeModal="closeModalDetail"
      :trx="trx" />
  </div>
</template>
<script>
import moment from 'moment';
import { truncate } from 'lodash';
import trxApi from '@/api/transactions';
import profileApi from '@/api/profile';
import base64File from '@/library/base64File';
import popupErrorMessages from '../../../library/popup-error-messages';
import CreateSettlement from '../../components/payments/CreateSettlement.vue';
import emptyTransaction from '../../../assets/images/undraw/empty-transaction.svg';
import TransactionDetail from '../../components/payments/TransactionDetail.vue';

const id_perorangan = '06ef4ce0-4af2-4e3f-9c11-d275a8d76a9c';

export default {
  name: 'Payments',
  components: {
    CreateSettlement,
    TransactionDetail,
  },
  metaInfo: {
    title: 'Payments',
  },
  data() {
    return {
      truncate,
      id_perorangan,
      emptyTransaction,
      page: 1,
      loading: {
        create_account: false,
        trx_list: false,
      },
      form: {},
      show_modal_add: false,
      bank_list: [],
      loader: null,
      loaderStack: 0,
      drEvent: null,
      account_list: [],
      // companyProfile: {},
      total_row: 0,
      page_limit: 10,
      trx_list: [],
      trx: {},
      show_modal_trx: false,
      trx_details: [],
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getTransactionList();
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    canCloseModalAdd() {
      return !this.loading.create_account;
    },
    userProfile() {
      return this.$store.state.auth.userProfile || {};
    },
    companyProfile() {
      return this.$store.state.auth.companyProfile || {};
    },
  },
  async mounted() {
    this.showLoader();
    await this.loadCompanyProfile();
    await this.accountList();
    if (this.account_list.length > 0) this.getTransactionList();
    this.hideLoader();
    this.$nextTick(() => {
      /* eslint-disable no-undef */
      this.drEvent = $('.dropify').dropify({
        messages: {
          default: 'Drag and drop a file here or click',
          replace: 'Drag and drop or click to replace',
          remove: 'Remove',
          error: 'Ooops, something wrong happended.',
        },
      });
    });
  },
  methods: {
    censored(str) {
      const end = str.slice(-4);
      return end.padStart(12, '*');
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async loadCompanyProfile() {
      const response = await profileApi.companyProfile();
      this.$store.dispatch('auth/updateCompanyProfile', response.data.profile);
    },
    submitCreateAccount(form) {
      this.form = form;
      this.$confirm(this.$t('payments.confirmation_create_account'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'info',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;

            const response = await trxApi.createSettlementAccount({
              workspace_id: this.activeWorkspace._id,
              data: this.form,
            }).catch(() => {});
            try {
              await popupErrorMessages(response);
              this.$message({
                message: this.$t('payments.success_create_account'),
                type: 'success',
              });
            } catch (error) {
              console.log(error);
            }
            // this.search = '';
            this.accountList();
            instance.confirmButtonLoading = false;
            cb();
            return;
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    closeModalAdd() {
      this.show_modal_add = false;
    },
    closeModalDetail() {
      this.show_modal_trx = false;
    },
    async showModalAdd() {
      if (!this.companyProfile.address || !this.companyProfile.business_entity_id || !this.companyProfile.business_type_id) {
        this.$swal({
          title: 'Ops!',
          icon: 'info',
          text: 'You haven\'t complete your company profile. Please kindly update your company profile first :)',
        }).then(() => {
          this.$parent.$refs.navbar.doShowModalCompanyProfile();
        });
        return;
      }
      this.showLoader();
      this.show_modal_add = true;
      await this.getBankList();
      this.hideLoader();
    },
    async getBankList() {
      const response = await trxApi.bankList();
      this.bank_list = response.data.rows;
    },
    async handleSendFile(file, target) {
      try {
        this.uploading[target] = true;
        const formData = new FormData();
        formData.append('file', file);
        const response = await trxApi.uploadAttachment({
          workspace_id: this.activeWorkspace._id,
          data: formData,
        }, (progressEvent) => {
          const p = parseInt((progressEvent.loaded / progressEvent.total) * 100, 0);
          this.upload_progress[target] = p;
          if (p === 100) {
            this.upload_status[target] = 'success';
          }
        }).catch(async () => {
          this.form[target] = await base64File(file);
        });
        // popupErrorMessages(response, () => {});
        if (response.data) this.form[target] = response.data.attachment.id;
        this.uploading[target] = false;
      } catch (error) {
        console.log(error);
      }
    },
    async accountList() {
      const response = await trxApi.listSettlementAccount({
        workspace_id: this.activeWorkspace._id,
      });
      await popupErrorMessages(response);
      const status_str = {
        active: 'Active',
        pending: 'In Review',
        inactive: 'Inactive',
        rejected: 'Rejected',
      };
      const status_color = {
        active: 'success',
        pending: 'warning',
        inactive: 'info',
        rejected: 'danger',
      };
      const status_description = {
        active: 'Your account has been actived and can be used for generate online transaction through conversation.',
        pending: 'Your request is still on review process. This may take minimal 3x24 hour to validate all of your data. Please kindly wait.',
        inactive: 'Your account is currently not active and can not be used for online transaction',
        rejected: 'Your request has been rejected. There is some document that not relevant with the requirement.',
      };
      this.account_list = response.data.rows.map((v) => {
        v.status_str = status_str[v.status];
        v.status_color = status_color[v.status];
        v.status_description = status_description[v.status];
        return v;
      });
    },
    async getTransactionList() {
      const status_str = {
        success: 'Success',
        waiting_payment: 'Waiting Payment',
        paid: 'Paid',
        canceled: 'Canceled',
        refund: 'Refund',
        expired: 'Expired',
      };
      const status_color = {
        success: 'success',
        waiting_payment: 'warning',
        paid: 'success',
        canceled: 'danger',
        refund: 'info',
        expired: 'danger',
      };
      this.loading.trx_list = true;
      const response = await trxApi.listTransactions({
        workspace_id: this.activeWorkspace._id,
        query: {
          page: this.page,
        },
      });
      this.loading.trx_list = false;
      popupErrorMessages(response);
      this.trx_list = response.data.rows.map((v) => {
        v.amount_str = `Rp. ${(v.total_amount || 0).toLocaleString()}`;
        v.bank_account = `${v.settlement_account.bank.name} - ${v.settlement_account.account_number.slice(-4)}`;
        v.creator_name = v.creator.name;
        v.created_at_str = moment(v.created_at).format('DD/MM/YYYY HH:mm');
        v.status_str = status_str[v.status];
        v.status_color = status_color[v.status];
        return v;
      });
      this.total_row = response.data.count;
    },
    async detailTransaction(item) {
      this.showLoader();
      const response = await trxApi.detailTransaction({
        workspace_id: this.activeWorkspace._id,
        transaction_id: item.id,
      });
      this.hideLoader();
      popupErrorMessages(response);
      this.trx = response.data.trx;
      this.trx_details = response.data.details;
      this.show_modal_trx = true;
    },
  },
};
</script>
<style>
.upload-demo > div {
  width: 100%;
}
</style>

import api from '../library/axios';

export default {
  businessEntities() {
    return api({
      method: 'get',
      url: '/api/business-entity',
    });
  },
  businessTypes() {
    return api({
      method: 'get',
      url: '/api/business-type',
    });
  },
  companyProfile() {
    return api({
      method: 'get',
      url: '/api/company-profile',
    });
  },
  accountProfile() {
    return api({
      method: 'get',
      url: '/api/account-profile',
    });
  },
  updateCompanyProfile({ data }, uploadHandler = null) {
    return api({
      method: 'patch',
      url: '/api/company-profile',
      data,
      uploadHandler,
    });
  },
  updateAccountProfile({ data }, uploadHandler = null) {
    return api({
      method: 'patch',
      url: '/api/account-profile',
      data,
      uploadHandler,
    });
  },
  myProfile() {
    return api({
      method: 'get',
      url: '/api/profile',
    });
  },
};
